import { useEffect } from "react";
import { useRouter } from "next/router";

import SSRProvider from 'react-bootstrap/SSRProvider';

import Script from "next/script";
import { GTM_ID, pageview } from '../lib/gtm'
import * as fbq from '../lib/fpixel'
import "../styles/layout.scss";

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    if (router.query.gclid) {
      sessionStorage.setItem("gclid", router.query.gclid);
    }
    router.events.on('routeChangeComplete', pageview)
    return () => {
      router.events.off('routeChangeComplete', pageview)
    }
    
  }, [router])

  return (
    
    <>
     
     <Script
        id="fb-pixel"
        rel= "preconnect"
        async
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />
      <Script
          id="gtag-base"
          rel= "preconnect"
          strategy="afterInteractive"
          async
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${GTM_ID}');
            `,
          }}
        />
     <SSRProvider>
        <Component {...pageProps} key={router.asPath} />
      </SSRProvider>
    </>
  )
}

export default MyApp;
