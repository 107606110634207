export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

export const pageview = (url) => {
  window.dataLayer.push({
    event: 'ViewContent',
    page: url,
  })
  
}

export const lead = ()=>{
  window.dataLayer.push({
    event: 'lead'
  })
}
